






import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import RootSlide from '@/mixins/RootSlide'
import EventType from '@/constants/event-type'

@Component
export default class SlideContainer extends mixins(RootSlide) {
    onRouterViewAssetsLoaded() {
        this.$emit(EventType.ASSETS_LOADED)
    }
}
